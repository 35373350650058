
import { Options, Vue } from "vue-class-component"
import { goToPortalHomePage } from "@/api/services/InviteService"
import { showChat } from "@/utils"

@Options({
  name: "ServerError",
  components: {}
})
export default class ServerError extends Vue {
  mounted() {
    setTimeout(() => showChat(), 1000)
  }

  toHomePage() {
    goToPortalHomePage()
  }
}
